import axios from '@src/services/axiosInstance';

export interface IMeResponse {
  success: 0 | 1;
  data: {
    role: Profile.AdminType['role'] | Profile.PartnerType['role'] | Profile.StaffType['role'];
    profile:
      | Profile.AdminType['profile']
      | Profile.PartnerType['profile']
      | Profile.StaffType['profile']
      | Profile.CustomerType['profile'];
  };
}

export const getMe = (): Promise<IMeResponse['data']> => {
  return axios.get('/api/protected/users/me').then(({ data }: { data: IMeResponse }) => data.data);
};
