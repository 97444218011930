import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './Recaptcha.module.less';

interface RecaptchaProps {
  recaptchaRef: React.MutableRefObject<ReCAPTCHA | null>;
  handleFieldChange: () => void;
  status?: 'error' | 'stale';
  changeState: (status: 'stale' | 'error') => void;
}

const Recaptcha: React.FC<RecaptchaProps> = ({ recaptchaRef, changeState, handleFieldChange }) => {
  return (
    <div className={`${styles['recaptcha-wrapper']}`}>
      <ReCAPTCHA
        sitekey={import.meta.env.VITE_RECAPTCHA_SITEKEY}
        ref={recaptchaRef}
        onChange={() => {
          handleFieldChange();
          changeState('stale');
        }}
      />
    </div>
  );
};

export default Recaptcha;
