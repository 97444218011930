import React from 'react';
import NotFoundPngUrl from './static/404.png';
import { Typography } from 'antd';
import Seo from '@src/components/SEO';

const NotFoundPage: React.FC = () => (
  <>
    <Seo title="404" />
    <div style={{ textAlign: 'center', padding: '4rem 0' }}>
      <Typography.Title style={{ letterSpacing: '0.05em', fontSize: '2.4rem' }}>404 Not Found</Typography.Title>
      <img
        src={NotFoundPngUrl}
        alt="404-image"
        style={{
          maxWidth: '350px',
          margin: '0 auto',
          display: 'block',
          marginBottom: '1rem',
        }}
      />
      <Typography.Link href="/" style={{ fontSize: '1.4rem' }} underline>
        Back Home
      </Typography.Link>
    </div>
  </>
);

export default NotFoundPage;
