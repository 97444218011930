import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.less';
import App from './App';
import CacheProvider from './components/QueryClient';
import enUs from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';

ReactDOM.render(
  <CacheProvider>
    <ConfigProvider locale={enUs}>
      <App />
    </ConfigProvider>
  </CacheProvider>,
  document.getElementById('root')
);
