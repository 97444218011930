import axios from '@src/services/axiosInstance';

export type TLogoutResponse = {
  success?: 0 | 1;
  data?: {
    msg: string;
  };
  msg?: string;
};

export const logout = (): Promise<TLogoutResponse> => {
  return axios.post('/api/protected/users/signout').then(({ data }) => data);
};
