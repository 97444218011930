import type { FormInstance } from 'antd';
import { message } from 'antd';
import React, { useRef, useState } from 'react';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { useHistory, useLocation } from 'react-router-dom';
import AuthWrapper from '@src/components/AuthWrapper';
import Message from '@src/components/AuthMesssage';
import { useMutation } from 'react-query';
import { useMount } from 'ahooks';
import { resetPassword } from '@src/services/authentication/resetPassword';
import { checkPassworStrong } from '@src/utils';

interface ISubmitProps {
  password: string;
  confirmedPassword: string;
}

const Reset: React.FC = () => {
  const formRef = useRef<FormInstance>();
  const [failedInfo, setFailedInfo] = useState<string | null>(null);
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const [token] = useState<string | null>(query.get('token'));

  useMount(() => {
    if (!token) {
      history.replace('/partner/login');
    }
  });

  const { mutate, isLoading, reset } = useMutation(resetPassword, {
    onSuccess: () => {
      message.success('Reset password successfully');
      history.push('/partner/login');
    },
    onError: () => {
      setFailedInfo('Failed to reset password');
    },
  });

  const handleResetSubmit = async (values: ISubmitProps) => {
    setFailedInfo(null);
    reset();
    if (!checkPassworStrong(values.password)) {
      setFailedInfo(
        'The password must contain at least three character categories among the following: Uppercase characters (A-Z) Lowercase characters (a-z) Digits (0-9) and minimum 6 characters'
      );
      return;
    }
    if (token) {
      await mutate({ token, payload: { newPassword: values.password } });
    }
  };

  return (
    <AuthWrapper title="Reset Your Password" docTitle="Reset Your Password">
      <ProForm
        formRef={formRef}
        submitter={{
          searchConfig: {
            submitText: 'Reset',
          },
          render: (_: unknown, dom: JSX.Element[]) => dom.pop(),
          submitButtonProps: {
            loading: isLoading,
            size: 'large',
            style: {
              width: '100%',
              marginTop: '1rem',
            },
          },
        }}
        onFinish={handleResetSubmit}
      >
        {failedInfo && <Message content={failedInfo} type="error" />}
        <ProFormText.Password
          name="password"
          label="Password"
          placeholder={''}
          rules={[
            {
              required: true,
              message: 'Please input password',
            },
          ]}
        />
        <ProFormText.Password
          name="confirmedPassword"
          label="Confirmed Password"
          placeholder={''}
          rules={[
            {
              required: true,
              message: 'Please input confirmed password',
            },
            ({ getFieldValue }: { getFieldValue: (name: string) => string }) => ({
              validator(_: unknown, value: string | undefined) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        />
      </ProForm>
    </AuthWrapper>
  );
};

export default Reset;
