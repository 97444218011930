import axios from '@src/services/axiosInstance';
import { AxiosResponse } from 'axios';

export type TCustomerSigninBody = {
  email?: string;
  password?: string;
  /** Recaptcha token required when user failed to log in several times. */
  recaptchaToken?: string;
};

export type TCustomerLoginResponse = {
  success?: 0 | 1;
  msg?: string;
  data?: {
    isFirstSignedIn: boolean;
    mag: string;
  };
  isRecaptchaRequired?: boolean;
};

export const customerLogin = (customerLoginPayload: TCustomerSigninBody): Promise<TCustomerLoginResponse> => {
  return axios
    .post<TCustomerSigninBody, AxiosResponse<TCustomerLoginResponse>>(
      '/api/general/auth/customer/signin',
      customerLoginPayload
    )
    .then((response) => {
      return response.data;
    });
};
