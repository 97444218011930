/* eslint-disable @typescript-eslint/no-explicit-any */
import { TPermissionItem } from '@src/services/authentication/getMyPermissions';
import { getCloudinarySignature } from '@src/services/upload/getCloudinarySignature';
import { Rule } from 'antd/lib/form';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { some } from 'lodash-es';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

export const checkPassworStrong = (password: string): boolean => {
  if (/[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && password.length > 5) {
    return true;
  }

  return false;
};

export const status: {
  label: string;
  value: string;
}[] = [
  {
    value: '0',
    label: 'New',
  },
  {
    value: '1',
    label: 'Assigned to partner',
  },
  {
    value: '2',
    label: 'Assigned to staff',
  },
  {
    value: '3',
    label: 'Inspection ready',
  },
  {
    value: '4',
    label: 'Inspection completed',
  },
  {
    value: '5',
    label: 'Quoted',
  },
  {
    value: '6',
    label: 'Quotation accepted',
  },
];

export const hasDuplicates = (array: any[]): boolean => {
  return some(array, function (elt, index) {
    return array.indexOf(elt) !== index;
  });
};

export const statusMap = (statusCode: string): { color?: string; msg: string } => {
  switch (statusCode) {
    case '0':
      return {
        msg: 'New',
      };
    case '1':
      return {
        color: 'blue',
        msg: 'Assigned to partner',
      };
    case '2':
      return {
        color: 'geekblue',
        msg: 'Assigned to staff',
      };
    case '3':
      return {
        color: 'magenta',
        msg: 'Inspection ready',
      };
    case '4':
      return {
        color: 'cyan',
        msg: 'Inspection completed',
      };
    case '5':
      return {
        color: 'orange',
        msg: 'Quoted',
      };
    case '6':
      return {
        color: 'green',
        msg: 'Quotation accepted',
      };
    default:
      return {
        msg: 'Unknown status',
      };
  }
};

export const getTimeStamp = (time: string): string => {
  return (moment(time).unix() * 1000).toString();
};

export const requireMap = (fieldName: string, initialRequireList: Rule[] = []): Rule[] => {
  return [
    ...initialRequireList,
    {
      required: true,
      message: `Please input ${fieldName}`,
    },
  ];
};

export const states = [
  {
    value: 'NSW',
    label: 'NSW',
  },
  {
    value: 'VIC',
    label: 'VIC',
  },
  {
    value: 'QLD',
    label: 'QLD',
  },
  {
    value: 'WA',
    label: 'WA',
  },
  {
    value: 'SA',
    label: 'SA',
  },
  {
    value: 'TAS',
    label: 'TAS',
  },
  {
    value: 'ACT',
    label: 'ACT',
  },
  {
    value: 'NT',
    label: 'NT',
  },
];

interface ICloudinaryUploadProps {
  file: string | Blob | RcFile | File;
  preset: string;
  tags?: string[];
  onUploadProgress?: (progressEvt: ProgressEvent) => void;
}

export const cloudinaryUpload = async ({
  file,
  onUploadProgress,
  preset,
  tags,
}: ICloudinaryUploadProps): Promise<AxiosResponse<any>> => {
  try {
    // First request the backend to get the signature
    const signatureResponse = await getCloudinarySignature({
      upload_preset: preset,
      tags: tags?.join(','),
    });
    if (!signatureResponse?.success) {
      throw new Error('Cannot get signature from Cloudinary');
    }

    // then request to upload image to cloudinary
    const formData = new FormData();
    formData.append('file', file);
    const timestamp = signatureResponse.data?.timestamp;
    const signature = signatureResponse.data?.signature;

    const cloudinaryUploadResponse = await axios.post<any>(
      `https://api.cloudinary.com/v1_1/${import.meta.env.VITE_CLOUDINARY_CLOUD_NAME}/image/upload?api_key=${
        import.meta.env.VITE_CLOUDINARY_API_KEY
      }&timestamp=${timestamp}&signature=${signature}&upload_preset=${preset}${tags ? `&tags=${tags.join(',')}` : ''}`,
      formData,
      {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': 'multipart/form-data;',
        },
        onUploadProgress,
      }
    );

    return cloudinaryUploadResponse;
  } catch (error) {
    throw new Error('Failed to upload');
  }
};

export const capitalize = (s?: string): string | undefined => s && s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const sleep = (ms: number): Promise<any> =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    }, ms)
  );

export const getRoleKey = (permissions?: Record<string, TPermissionItem[]>): number[] => {
  const roleArr: number[] = [];

  if (permissions) {
    Object.keys(permissions).forEach((permissionCategory) => {
      permissions[permissionCategory].forEach((permission: TPermissionItem) => {
        roleArr.push(permission.id);
      });
    });
  }

  return roleArr;
};

export const onPreview = async (file: UploadFile): Promise<void> => {
  let src = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as RcFile);
      reader.onload = () => resolve(reader.result as string);
    });
  }
  const image = new Image();
  image.src = src as string;
  image.style.width = '100vw';
  const imgWindow = window.open(src);
  imgWindow!.document.write(image.outerHTML);
};
