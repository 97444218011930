import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { PageLoading } from '@ant-design/pro-layout';
import { IMeResponse, getMe } from '@src/services/authentication/getMe';
import { useQuery } from 'react-query';

const SentryRoute = Sentry.withSentryRouting(Route);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PublicRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const { data: me, isLoading } = useQuery<IMeResponse['data'], Error>('me', getMe, {
    staleTime: Infinity,
    onSettled: (data) => {
      if (data) {
        Sentry.setUser({ email: data.profile.email });
      }
    },
  });

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <SentryRoute
      {...rest}
      render={() => {
        return me?.profile ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
            <Component />
          </Sentry.ErrorBoundary>
        );
      }}
    />
  );
};

export default PublicRoute;
