import type { AxiosResponse } from 'axios';
import axios from '@src/services/axiosInstance';

type TCustomerSignupPayload = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  recaptchaToken: string;
};

type TCustomerSignupResponse = {
  success?: 0 | 1;
  data?: {
    email: string;
  };
  msg?: string;
};

export const customerSignup = (customerSignupPayload: TCustomerSignupPayload): Promise<TCustomerSignupResponse> => {
  return axios
    .post<TCustomerSignupPayload, AxiosResponse<TCustomerSignupResponse>>(
      '/api/general/auth/customer/signup',
      customerSignupPayload
    )
    .then(({ data }) => data);
};
