import React, { useState } from 'react';
import { useCountDown, useMount } from 'ahooks';
import AuthWrapper from '@src/components/AuthWrapper';
import styles from './index.module.less';
import { Alert, Button } from 'antd';
import { PageLoading } from '@ant-design/pro-layout';
import { useHistory, useLocation } from 'react-router-dom';
import { verifyEmail } from '@src/services/authentication/verifyEmail';

const Verify: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const [token] = useState<string | null>(query.get('token'));

  const [countdown, setTargetDate] = useCountDown({
    onEnd: () => {
      history.replace('/partner/login');
    },
  });

  const [success, setIsSuccess] = useState(false);

  const verify = async (token: string) => {
    try {
      const msg = await verifyEmail({ token });
      if (msg && msg.success !== 1) {
        throw new Error(msg.msg);
      }
      setIsSuccess(true);
      setTargetDate(Date.now() + 5000);
    } catch (error) {
      history.replace('/partner/login');
    }
  };

  useMount(() => {
    if (!token) {
      history.replace('/partner/login');
    } else {
      verify(token);
    }
  });

  return (
    <AuthWrapper docTitle="Email Verification">
      {!success && <PageLoading />}
      {success && (
        <div className={styles.result}>
          <Alert
            message="Email verified Successfully"
            description={
              <p className={styles['mp-0']}>
                {`After ${Math.round(countdown / 1000)} seconds this page will go to`}{' '}
                <Button type="link" onClick={() => history.replace('/partner/login')} className={styles['mp-0']}>
                  log in
                </Button>
              </p>
            }
            type="success"
            showIcon
          />
        </div>
      )}
    </AuthWrapper>
  );
};

export default Verify;
