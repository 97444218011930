import React from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title?: string;
}

const Seo: React.FC<SeoProps> = ({ title }) => (
  <Helmet>
    <title>{title ? `${title} - ` : ''}Konec Solution</title>
  </Helmet>
);

export default Seo;
