import type { FormInstance } from 'antd';
import { Alert } from 'antd';
import React, { useRef } from 'react';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import styles from './index.module.less';
import AuthWrapper from '@src/components/AuthWrapper';
import Message from '@src/components/AuthMesssage';
import { useMutation } from 'react-query';
import { sendRecoveryEmail } from '@src/services/authentication/sentRecoveryEmail';

const Recovery: React.FC = () => {
  const formRef = useRef<FormInstance>();
  const { mutate, data, isLoading, reset, error } = useMutation(sendRecoveryEmail);

  const handleForgetPw = async ({ email }: { email: string }) => {
    reset();
    await mutate({ email });
  };

  return (
    <AuthWrapper
      title="Recovery Your Account"
      docTitle="Recovery Your Account"
      desc={
        data
          ? undefined
          : 'Forgot your account’s password or having trouble logging into your account? Enter your email address and we’ll send you a recovery link.'
      }
    >
      {data && data.success && (
        <div className={styles.result}>
          <Alert
            message={`Account recovery email sent to ${data.data?.email}`}
            description="If you don’t see this email in your inbox within 15 minutes, look for it in your junk mail folder. If you find it there, please mark it as “Not Junk”."
            type="success"
            showIcon
          />
        </div>
      )}
      {!data && (
        <>
          <ProForm
            formRef={formRef}
            submitter={{
              searchConfig: {
                submitText: 'Send recovery email',
              },
              render: (_: unknown, dom: JSX.Element[]) => dom.pop(),
              submitButtonProps: {
                loading: isLoading,
                size: 'large',
                style: {
                  width: '100%',
                  marginTop: '1rem',
                },
              },
            }}
            onFinish={async (values: { email: string }) => {
              handleForgetPw(values);
            }}
          >
            {error && <Message content="Failed to send recovery email" type="error" />}
            <ProFormText
              name="email"
              label={<span className={styles.label}>Email</span>}
              placeholder={''}
              rules={[
                {
                  required: true,
                  message: 'Please input email address',
                },
                {
                  type: 'email',
                  message: 'Please input email address',
                },
              ]}
            />
          </ProForm>
        </>
      )}
    </AuthWrapper>
  );
};

export default Recovery;
