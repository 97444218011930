import axios from '@src/services/axiosInstance';
import { AxiosResponse } from 'axios';

type TActivateAccountProps = {
  token: string;
  payload: {
    newPassword: string;
  };
};

type TActivateAccountResponse = {
  success: 0 | 1;
  data?: {
    msg: string;
  };
};

export const activateAccount = ({ token, payload }: TActivateAccountProps): Promise<TActivateAccountResponse> => {
  return axios
    .post<TActivateAccountProps['payload'], AxiosResponse<TActivateAccountResponse>>(
      '/api/general/auth/business/user-activate',
      payload,
      { params: { token } }
    )
    .then(({ data }) => data);
};
