import axios from '@src/services/axiosInstance';

export type TPermissionItem = {
  id: number;
  category: string;
  desc: string;
  api: string;
  method: string;
  Role_Permission: {
    roleId: number;
    permissionId: number;
  };
};

export type TGetMyPermissionsResponse = {
  success: 0 | 1;
  data: {
    permissions: Record<string, TPermissionItem[]>;
  };
};

export const getMyPermissions = (): Promise<TGetMyPermissionsResponse['data']['permissions']> => {
  return axios
    .get('/api/protected/rbac/permissions/me')
    .then(({ data }: { data: TGetMyPermissionsResponse }) => data.data.permissions);
};
