import axios from '@src/services/axiosInstance';
import { AxiosResponse } from 'axios';

export type TGetCloudinarySignatureRequestBody = {
  upload_preset: string;
  tags?: string;
};

export type TGetGetCloudinarySignatureResponse = {
  success?: 0 | 1;
  data?: {
    signature?: string;
    timestamp?: number;
  };
};

export const getCloudinarySignature = (
  data: TGetCloudinarySignatureRequestBody
): Promise<TGetGetCloudinarySignatureResponse> => {
  return axios
    .get<unknown, AxiosResponse<TGetGetCloudinarySignatureResponse>>(
      `/api/protected/cloudinary/signature/${data.upload_preset}`,
      {
        params: data,
      }
    )
    .then((response) => {
      return response.data;
    });
};
