import React from 'react';
import { ToTopOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';
import { useScroll } from 'ahooks';
import { Button } from 'antd';
import { motion } from 'framer-motion';
import { animateScroll } from 'react-scroll';
import type { Variant } from 'framer-motion';
import styles from './index.module.less';

const Footer: React.FC = () => {
  const defaultMessage = 'Konec';

  const scroll = useScroll();

  const variants: Record<string, Variant> = {
    hidden: {
      x: 100,
      transition: { duration: 0.5 },
    },
    show: {
      x: -24,
      transition: { duration: 0.5 },
    },
  };

  const gotoTop = () => {
    animateScroll.scrollToTop({ duration: 800 });
  };

  return (
    <>
      <motion.div
        variants={variants}
        animate={scroll.top > 500 ? 'show' : 'hidden'}
        className={styles.toTop}
        initial={false}
        whileHover={{
          scale: 1.1,
          transition: { duration: 0.25, yoyo: Infinity },
        }}
      >
        <Button icon={<ToTopOutlined />} size="large" onClick={gotoTop} type="default">
          Top
        </Button>
      </motion.div>

      <DefaultFooter
        copyright={`2021 ${defaultMessage}. All Rights Reserved`}
        links={[
          {
            key: 'Official Website',
            title: 'Official Website',
            href: 'https://konec.com.au/',
            blankTarget: true,
          },
          {
            key: 'Konec Healthcare',
            title: 'Konec Healthcare',
            href: 'https://www.konechealthcare.com.au/',
            blankTarget: true,
          },
        ]}
      />
    </>
  );
};

export default Footer;
