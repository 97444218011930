import AuthWrapper from '@src/components/AuthWrapper';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import type { FormInstance } from '@ant-design/pro-form';
import React, { useEffect, useRef, useState } from 'react';
import { useRecaptcha } from '@src/components/Recaptcha/useRecaptcha';
import { Alert, Form, Typography } from 'antd';
import Recaptcha from '@src/components/Recaptcha';
import styles from './index.module.less';
import Message from '@src/components/AuthMesssage';
import { Link } from 'react-router-dom';
import { checkPassworStrong } from '@src/utils';
import { customerSignup } from '@src/services/authentication/customerSignup';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

type TSignupRequestBody = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  recaptchaToken?: string;
};

const Signup: React.FC = () => {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { recaptcha, recaptchaRef, actions: recaptchaActions } = useRecaptcha({ required: true, status: 'stale' });
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const formRef = useRef<FormInstance>();
  const history = useHistory();

  const {
    mutate: signupMutate,
    isLoading,
    isSuccess,
    data,
    error,
  } = useMutation(customerSignup, {
    onError: () => {
      recaptchaActions.resetRecaptcha();
    },
  });

  useEffect(() => {
    if (typeof error === 'string') {
      setErrMsg(error);
    }
  }, [error]);

  const handleChangeField = () => {
    if (
      formRef.current &&
      formRef.current.getFieldValue('firstName') &&
      formRef.current.getFieldValue('lastName') &&
      formRef.current.getFieldValue('email') &&
      formRef.current.getFieldValue('password') &&
      formRef.current.getFieldValue('confirmedPassword') &&
      recaptchaRef.current?.getValue()
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  };

  const handleSubmit = async (values: TSignupRequestBody & { confirmedPassword: string }) => {
    setErrMsg(null);
    const { email, firstName, lastName, password, confirmedPassword } = values;
    if (password !== confirmedPassword) {
      setErrMsg('Different password input');
      return;
    }
    if (!checkPassworStrong(password)) {
      setErrMsg(
        'The password must contain at least three character categories among the following: Uppercase characters (A-Z) Lowercase characters (a-z) Digits (0-9) and minimum 6 characters'
      );
      return;
    }

    signupMutate({
      email,
      firstName,
      lastName,
      password,
      recaptchaToken: recaptchaActions.getRecaptchaValue() ?? '',
    });
  };

  useEffect(() => {
    // currently disable customer login
    history.replace('/');
  }, []);

  return (
    <AuthWrapper footer={false} docTitle="Join us">
      {isSuccess && data?.data?.email ? (
        <div className={styles.result}>
          <Alert
            message="Sign up Successfully"
            description={
              <>
                <p>
                  An validation email has been sent to <Typography.Text strong>{data.data.email}</Typography.Text>.
                  Please check your mail box
                </p>
                <p className={styles['mb-0']}>
                  Go to <Link to="/partner/login">Log in</Link>
                </p>
              </>
            }
            type="success"
            showIcon
          />
        </div>
      ) : (
        <ProForm
          formRef={formRef}
          onChange={handleChangeField}
          submitter={{
            searchConfig: {
              submitText: 'Sign up',
            },
            render: (_: unknown, dom: JSX.Element[]) => dom.pop(),
            submitButtonProps: {
              loading: isLoading,
              disabled: disableSubmit,
              size: 'large',
              style: {
                width: '100%',
                marginBottom: '3rem',
              },
            },
          }}
          onFinish={async (values: TSignupRequestBody & { confirmedPassword: string }) => {
            handleSubmit(values);
          }}
        >
          {errMsg && <Message content={errMsg} type={'error'} />}
          <ProForm.Group>
            <ProFormText
              name="firstName"
              label={<span>First Name</span>}
              width={148}
              placeholder={''}
              rules={[
                {
                  required: true,
                  message: 'Please input first name',
                },
              ]}
            />
            <ProFormText
              name="lastName"
              label={<span>Last Name</span>}
              width={148}
              placeholder={''}
              rules={[
                {
                  required: true,
                  message: 'Please input last name',
                },
              ]}
            />
          </ProForm.Group>

          <ProFormText
            name="email"
            label={<span>Email</span>}
            placeholder={''}
            rules={[
              {
                required: true,
                message: 'Please input email',
              },
              {
                type: 'email',
                message: 'Please input email',
              },
            ]}
          />
          <ProFormText.Password
            name="password"
            label={<span>Password</span>}
            placeholder={''}
            rules={[
              {
                required: true,
                message: 'Please input password',
              },
            ]}
          />
          <ProFormText.Password
            name="confirmedPassword"
            label={<span>Confirmed Password</span>}
            placeholder={''}
            rules={[
              {
                required: true,
                message: 'Please input confirmed password',
              },
            ]}
          />
          {recaptcha.required && (
            <Form.Item noStyle>
              <Recaptcha
                recaptchaRef={recaptchaRef}
                status={recaptcha.status}
                changeState={recaptchaActions.setRecaptchaStatus}
                handleFieldChange={handleChangeField}
              />
            </Form.Item>
          )}
        </ProForm>
      )}
    </AuthWrapper>
  );
};

export default Signup;
