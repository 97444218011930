import { useRef, useState } from 'react';
import type ReCAPTCHA from 'react-google-recaptcha';

export interface IRecaptcha {
  required: boolean;
  status: 'error' | 'stale';
}

export interface IUseRecaptchaProps {
  required: boolean;
  status: 'error' | 'stale';
}

interface IUseRecapcthaReturn {
  recaptcha: IRecaptcha;
  recaptchaRef: React.MutableRefObject<ReCAPTCHA | null>;
  actions: {
    setRecaptchaStatus: (status: IRecaptcha['status']) => void;
    resetRecaptcha: () => void;
    getRecaptchaValue: () => string | null | undefined;
    requireRecaptcha: () => void;
  };
}

export const useRecaptcha = (props: IUseRecaptchaProps = { required: false, status: 'stale' }): IUseRecapcthaReturn => {
  const [recaptcha, setRecaptcha] = useState<IRecaptcha>({
    required: props.required,
    status: props.status,
  });

  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const setRecaptchaStatus = (status: IRecaptcha['status']) => {
    setRecaptcha((prevState) => ({
      ...prevState,
      status,
    }));
  };

  const resetRecaptcha = () => {
    recaptchaRef.current?.reset();
  };

  const getRecaptchaValue = () => {
    return recaptchaRef.current?.getValue();
  };

  const requireRecaptcha = () => {
    setRecaptcha((prevState) => ({
      ...prevState,
      required: true,
    }));
  };

  return {
    recaptcha,
    recaptchaRef,
    actions: {
      setRecaptchaStatus,
      resetRecaptcha,
      getRecaptchaValue,
      requireRecaptcha,
    },
  };
};
