import { FormInstance, message } from 'antd';
import { Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { Link, useHistory } from 'react-router-dom';
import Recaptcha from '@src/components/Recaptcha';
import { useRecaptcha } from '@src/components/Recaptcha/useRecaptcha';
import AuthWrapper from '@src/components/AuthWrapper';
import styles from './index.module.less';
import Message from '@src/components/AuthMesssage';
import { useMutation, useQueryClient } from 'react-query';
import { customerLogin, TCustomerSigninBody } from '@src/services/authentication/customerLogin';

const Login: React.FC = () => {
  const formRef = useRef<FormInstance>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { recaptcha, recaptchaRef, actions: recaptchaActions } = useRecaptcha();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const {
    mutate: loginMutate,
    isLoading,
    error,
    reset,
  } = useMutation(customerLogin, {
    onSuccess: async () => {
      queryClient.invalidateQueries('me');
      queryClient.invalidateQueries('my-permissions');
      message.success('login successfully');
      history.push('/');
    },
    onError: (err) => {
      if (typeof err === 'string') {
        if (!recaptcha.required && err.includes('recaptcha')) {
          recaptchaActions.requireRecaptcha();
          setDisableSubmit(true);
        } else {
          recaptchaActions.resetRecaptcha();
        }
      }
    },
  });

  const handleSigninSubmit = async (values: TCustomerSigninBody) => {
    let payload = values;
    const token = recaptchaActions.getRecaptchaValue();
    reset();
    if (recaptcha.required && token) {
      payload = { ...values, recaptchaToken: token };
    }

    loginMutate(payload);
  };

  const fieldNotEmpty = () => {
    if (!recaptcha.required)
      return formRef.current?.getFieldValue('email') && formRef.current?.getFieldValue('password');

    return (
      recaptchaActions.getRecaptchaValue() &&
      formRef.current?.getFieldValue('email') &&
      formRef.current?.getFieldValue('password')
    );
  };

  const handleFieldChange = () => {
    if (fieldNotEmpty()) {
      if (disableSubmit) {
        setDisableSubmit(false);
      }
    } else if (!disableSubmit) {
      setDisableSubmit(true);
    }
  };

  useEffect(() => {
    // currently disable customer login
    history.replace('/');
  }, []);

  return (
    <AuthWrapper docTitle="Login">
      <ProForm
        formRef={formRef}
        onChange={handleFieldChange}
        submitter={{
          searchConfig: {
            submitText: 'Login',
          },
          render: (_: unknown, dom: JSX.Element[]) => dom.pop(),
          submitButtonProps: {
            loading: isLoading,
            disabled: disableSubmit,
            size: 'large',
            style: {
              width: '100%',
              marginTop: '1rem',
            },
          },
        }}
        onFinish={async (values: TCustomerSigninBody) => {
          handleSigninSubmit(values);
        }}
      >
        {error && typeof error === 'string' && <Message content={error} type={'error'} />}
        <ProFormText
          name="email"
          label={<span>Email</span>}
          placeholder={''}
          rules={[
            {
              required: true,
              message: 'Please input email',
            },
          ]}
        />
        <ProFormText.Password
          name="password"
          label={<span>Password</span>}
          placeholder={''}
          rules={[
            {
              required: true,
              message: 'Please input password',
            },
          ]}
        />
        {recaptcha.required && (
          <Form.Item noStyle>
            <Recaptcha
              recaptchaRef={recaptchaRef}
              status={recaptcha.status}
              changeState={recaptchaActions.setRecaptchaStatus}
              handleFieldChange={handleFieldChange}
            />
          </Form.Item>
        )}
      </ProForm>
      <div className={styles.bottomBtn}>
        <div>
          <Link to={'/user/signup'}>Join us</Link>
        </div>
        <div>
          <Link to={'/user/account-recovery'}>Forgot your password?</Link>
        </div>
      </div>
      <Link to={'/partner/login'}>
        <p className={styles.partnerBtn}>Are you our partner / sale?</p>
      </Link>
    </AuthWrapper>
  );
};

export default Login;
