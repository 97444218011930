import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import styles from './index.module.less';
import { Typography } from 'antd';
import LogoUrl from '@src/static/konec_black_logo.png';
import Seo from '@src/components/SEO';

interface AuthWrapperProps {
  children: React.ReactNode;
  title?: string;
  docTitle?: string;
  desc?: string;
  footer?: boolean;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children, title, docTitle, desc, footer = true }) => {
  return (
    <>
      <Seo title={docTitle} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>
              <Link to="/">
                <img alt="logo" className={styles.logo} src={LogoUrl} />
              </Link>
              <div className={styles.main}>
                {title && (
                  <div className={`${styles.desc} ${desc ? '' : styles['pb-16']}`}>
                    <Typography.Title level={2} style={{ textAlign: 'center' }}>
                      {title}
                    </Typography.Title>
                    <Typography.Text>{desc}</Typography.Text>
                  </div>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
        {footer && <Footer />}
      </div>
    </>
  );
};

export default AuthWrapper;
