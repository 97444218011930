import axios from '@src/services/axiosInstance';

type TVerifyEmailPayload = {
  token: string;
};

type TVerifyEmailResponse = {
  success?: 0 | 1;
  data?: {
    email: string;
    msg: string;
  };
  msg?: string;
};

export const verifyEmail = ({ token }: TVerifyEmailPayload): Promise<TVerifyEmailResponse> => {
  return axios
    .get('/api/general/auth/verify', {
      params: {
        token,
      },
    })
    .then(({ data }) => data);
};
