import React, { Suspense } from 'react';

import { Redirect, Route } from 'react-router-dom';
import DashbordWrapper from '../DashboardWrapper';
import * as Sentry from '@sentry/react';
import { PageLoading } from '@ant-design/pro-layout';
import { IMeResponse, getMe } from '@src/services/authentication/getMe';
import { getMyPermissions } from '@src/services/authentication/getMyPermissions';
import { useQuery } from 'react-query';
import { message } from 'antd';

const SentryRoute = Sentry.withSentryRouting(Route);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute: React.FC<any> = ({ component: file, ...rest }) => {
  const { data, isLoading } = useQuery<IMeResponse['data'], Error>('me', getMe, {
    staleTime: Infinity,
    onError: (err) => {
      if (err && typeof err === 'string') {
        message.error(err);
        return;
      }

      message.error('Please login');
    },
    onSettled: (data, error) => {
      if (data) {
        Sentry.setUser({ email: data.profile.email });
      }

      if (error) {
        Sentry.configureScope((scope) => scope.setUser(null));
      }
    },
  });
  const { isLoading: isMyPermissionsLoading } = useQuery('my-permissions', getMyPermissions, {
    staleTime: Infinity,
    enabled: !!data,
  });

  if (isLoading || isMyPermissionsLoading) {
    return <PageLoading />;
  }

  const Component = React.lazy(file);

  return (
    <SentryRoute
      {...rest}
      render={({ location }) => {
        return data?.profile ? (
          <DashbordWrapper>
            <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
              <Suspense fallback={<div>Loading...</div>}>
                <Component />
              </Suspense>
            </Sentry.ErrorBoundary>
          </DashbordWrapper>
        ) : (
          <Redirect to={{ pathname: '/partner/login', state: { from: location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
