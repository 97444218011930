import React, { useState } from 'react';

import type { ProSettings } from '@ant-design/pro-layout';
import ProLayout from '@ant-design/pro-layout';
import { SmileOutlined, BookOutlined, UserOutlined, ContactsOutlined, DatabaseOutlined } from '@ant-design/icons';
import GlobalHeaderRight from '../HeaderRightContent';
import { useHistory } from 'react-router-dom';
import Footer from '../Footer';
import { useGetMe } from '@src/hooks/useQueryHook';

const DashbordWrapper: React.FC = ({ children }) => {
  const history = useHistory();
  const me = useGetMe();
  const [settings] = useState<Partial<ProSettings> | undefined>({
    fixSiderbar: true,
    layout: 'mix',
    contentWidth: 'Fluid',
    splitMenus: false,
    navTheme: 'light',
    primaryColor: '#1890ff',
  });

  const routes = {
    ROOT: {
      route: {
        path: '/',
        routes: [
          {
            path: '/welcome',
            name: 'Welcome',
            icon: <SmileOutlined />,
          },
          {
            path: '/bookings',
            name: 'Bookings',
            icon: <BookOutlined />,
          },
          {
            path: '/product',
            name: 'Product',
            icon: <DatabaseOutlined />,
            routes: [
              {
                name: 'Product',
                path: '/product/items',
              },
              {
                name: 'Category',
                path: '/product/categories',
              },
            ],
          },
          {
            path: '/users',
            name: 'Users',
            icon: <ContactsOutlined />,
            routes: [
              {
                name: 'Staff',
                path: '/users/staff',
                hideInMenu: true,
              },
              {
                name: 'Partners',
                path: '/users/partners',
              },
              {
                name: 'Admins',
                path: '/users/admins',
              },
              {
                name: 'Customers',
                path: '/users/customers',
              },
            ],
          },
          {
            path: '/account',
            name: 'Account',
            icon: <UserOutlined />,
            routes: [
              {
                path: '/account/profile',
                name: 'My Profile',
              },
              {
                path: '/account/roles',
                name: 'Roles',
              },
            ],
          },
        ],
      },
      location: {
        pathname: '/',
      },
    },
    ADMIN: {
      route: {
        path: '/',
        routes: [
          {
            path: '/welcome',
            name: 'Welcome',
            icon: <SmileOutlined />,
          },
          {
            path: '/bookings',
            name: 'Bookings',
            icon: <BookOutlined />,
          },
          {
            path: '/product',
            name: 'Product',
            icon: <DatabaseOutlined />,
            routes: [
              {
                name: 'Product',
                path: '/product/items',
              },
              {
                name: 'Category',
                path: '/product/categories',
              },
            ],
          },
          {
            path: '/users',
            name: 'Users',
            icon: <ContactsOutlined />,
            routes: [
              {
                name: 'Staff',
                path: '/users/staff',
                hideInMenu: true,
              },
              {
                name: 'Partners',
                path: '/users/partners',
              },
              {
                name: 'Admins',
                path: '/users/admins',
                hideInMenu: true,
              },
              {
                name: 'Customers',
                path: '/users/customers',
              },
            ],
          },
          {
            path: '/account',
            name: 'Account',
            icon: <UserOutlined />,
            routes: [
              {
                path: '/account/profile',
                name: 'My Profile',
              },
              {
                path: '/account/roles',
                name: 'Roles',
              },
            ],
          },
        ],
      },
      location: {
        pathname: '/',
      },
    },
    PARTNER: {
      route: {
        path: '/',
        routes: [
          {
            path: '/welcome',
            name: 'Welcome',
            icon: <SmileOutlined />,
          },
          {
            path: '/bookings',
            name: 'Bookings',
            icon: <BookOutlined />,
          },
          {
            path: '/product',
            name: 'Product',
            icon: <DatabaseOutlined />,
            hideInMenu: !me?.role.isOfficial,
            routes: [
              {
                name: 'Product',
                path: '/product/items',
              },
              {
                name: 'Category',
                path: '/product/categories',
              },
            ],
          },
          {
            path: '/users',
            name: 'Users',
            icon: <ContactsOutlined />,
            routes: [
              {
                name: 'Staff',
                path: '/users/staff',
              },
              {
                name: 'Partners',
                path: '/users/partners',
                hideInMenu: true,
              },
              {
                name: 'Admins',
                path: '/users/admins',
                hideInMenu: true,
              },
              {
                name: 'Customers',
                path: '/users/customers',
              },
            ],
          },
          {
            path: '/account',
            name: 'Account',
            icon: <UserOutlined />,
            routes: [
              {
                path: '/account/profile',
                name: 'My Profile',
              },
              {
                path: '/account/roles',
                name: 'Roles',
              },
            ],
          },
        ],
      },
      location: {
        pathname: '/',
      },
    },
    STAFF: {
      route: {
        path: '/',
        routes: [
          {
            path: '/welcome',
            name: 'Welcome',
            icon: <SmileOutlined />,
          },
          {
            path: '/bookings',
            name: 'Bookings',
            icon: <BookOutlined />,
          },
          {
            path: '/product',
            name: 'Product',
            icon: <DatabaseOutlined />,
            hideInMenu: !me?.role.isOfficial,
            routes: [
              {
                name: 'Product',
                path: '/product/items',
              },
              {
                name: 'Category',
                path: '/product/categories',
              },
            ],
          },
          {
            path: '/users',
            name: 'Users',
            icon: <ContactsOutlined />,
            routes: [
              {
                name: 'Staff',
                path: '/users/staff',
                hideInMenu: true,
              },
              {
                name: 'Partners',
                path: '/users/partners',
                hideInMenu: true,
              },
              {
                name: 'Admins',
                path: '/users/admins',
                hideInMenu: true,
              },
              {
                name: 'Customers',
                path: '/users/customers',
              },
            ],
          },
          {
            path: '/account',
            name: 'Account',
            icon: <UserOutlined />,
            routes: [
              {
                path: '/account/profile',
                name: 'My Profile',
              },
              {
                path: '/account/roles',
                name: 'Roles',
                hideInMenu: true,
              },
            ],
          },
        ],
      },
      location: {
        pathname: '/',
      },
    },
    CUSTOMER: {
      route: {
        path: '/',
        routes: [
          {
            path: '/welcome',
            name: 'Welcome',
            icon: <SmileOutlined />,
          },
          {
            path: '/bookings',
            name: 'Bookings',
            icon: <BookOutlined />,
          },
          {
            path: '/product',
            name: 'Product',
            icon: <DatabaseOutlined />,
            routes: [
              {
                name: 'Product',
                path: '/product',
              },
              {
                name: 'Category',
                path: '/product/categories',
              },
            ],
          },
          {
            path: '/users',
            name: 'Users',
            icon: <ContactsOutlined />,
            routes: [
              {
                name: 'Staff',
                path: '/users/staff',
              },
              {
                name: 'Partners',
                path: '/users/partners',
              },
              {
                name: 'Admins',
                path: '/users/admins',
              },
              {
                name: 'Customers',
                path: '/users/customers',
              },
            ],
          },
          {
            path: '/account',
            name: 'Account',
            icon: <UserOutlined />,
            routes: [
              {
                path: '/account/profile',
                name: 'My Profile',
              },
              {
                path: '/account/roles',
                name: 'Roles',
              },
            ],
          },
        ],
      },
      location: {
        pathname: '/',
      },
    },
  };

  return (
    <div
      id="test-pro-layout"
      style={{
        height: '100vh',
      }}
    >
      <ProLayout
        //@ts-ignore
        {...routes[me?.role.type ?? 'STAFF']}
        location={{
          pathname: history.location.pathname,
        }}
        logo={<div className="logo" />}
        title={`Konec Solution`}
        breadcrumbRender={false}
        menuItemRender={(item, dom) => {
          const path = item.path || '/welcome';
          if (path.startsWith('http')) {
            return (
              <a href={path} target="_blank" rel="noreferrer">
                {dom}
              </a>
            );
          }
          return (
            <a
              onClick={() => {
                const path = item.path || '/welcome';
                history.push(path);
              }}
            >
              {dom}
            </a>
          );
        }}
        rightContentRender={() => <GlobalHeaderRight />}
        footerRender={() => <Footer />}
        {...settings}
      >
        {children}
      </ProLayout>
    </div>
  );
};

export default DashbordWrapper;
