import axios from 'axios';

const axiosInstance = axios.create();

const isProduction = import.meta.env.PROD;

axiosInstance.interceptors.request.use((config) => {
  config.withCredentials = true;

  if (isProduction) {
    const baseURL = import.meta.env.VITE_BACKEND_URL;
    return { ...config, baseURL };
  }

  return config;
});

axiosInstance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error?.response?.data?.msg) {
      return Promise.reject(error.response.data.msg);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
