import React from 'react';
import { Alert } from 'antd';
import type { AlertProps } from 'antd';
import styles from './index.module.less';

const Message: React.FC<{
  content: string;
  type?: AlertProps['type'];
}> = ({ content, type }) => <Alert className={styles.alert} message={content} type={type ?? 'success'} showIcon />;

export default Message;
