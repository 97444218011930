import axios from '@src/services/axiosInstance';

type TSendRecoveryEmailPayload = {
  email: string;
};

type TSendRecoveryEmailResponse = {
  success: 0 | 1;
  data?: {
    email: string;
  };
};

export const sendRecoveryEmail = ({ email }: TSendRecoveryEmailPayload): Promise<TSendRecoveryEmailResponse> => {
  return axios.get('/api/general/auth/forget_pw', { params: { email } }).then(({ data }) => data);
};
