import React, { useCallback } from 'react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu, message, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styles from './index.module.less';
import type { MenuInfo } from 'rc-menu/lib/interface';
import HeaderDropdown from '../HeaderDropdown';
import { queryClient } from '../QueryClient';
import { IMeResponse } from '@src/services/authentication/getMe';
import { useMutation } from 'react-query';
import { logout } from '@src/services/authentication/logout';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu }) => {
  const history = useHistory();
  const me = queryClient.getQueryData<IMeResponse['data']>('me');
  const logoutMutation = useMutation(logout, {
    onSuccess: async () => {
      message.success('You have logged out');
      setTimeout(() => {
        window.location.href = '/partner/login';
      }, 500);
    },
    onError: () => {
      message.error('Failed to logout');
    },
  });

  const loginOut = () => {
    logoutMutation.mutate();
  };

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === 'logout' && me?.profile) {
        loginOut();
        return;
      }
      history.push(`/account/${key}`);
    },
    [me]
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!me?.profile || !me.profile.email) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {menu && (
        <Menu.Item key="profile">
          <UserOutlined />
          Profile
        </Menu.Item>
      )}
      {menu && <Menu.Divider />}

      <Menu.Item key="logout">
        <LogoutOutlined />
        logout
      </Menu.Item>
    </Menu>
  );

  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        {me.profile?.AvatarCloudinary?.assetUrl ? (
          <Avatar size="small" className={styles.avatar} alt="avatar" src={me.profile?.AvatarCloudinary?.assetUrl} />
        ) : (
          <Avatar size="small" className={styles.avatar} alt="avatar" icon={<UserOutlined />} />
        )}
        <span className={`${styles.name} anticon`}>{me.profile.email}</span>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
