import axios from '@src/services/axiosInstance';
import { AxiosResponse } from 'axios';

export type TServiceSigninBody = {
  email?: string;
  password?: string;
  /** Recaptcha token required when user failed to log in several times. */
  recaptchaToken?: string;
};

export type TServiceLoginResponse = {
  success?: 0 | 1;
  msg?: string;
  data?: {
    isFirstSignedIn: boolean;
    mag: string;
  };
  isRecaptchaRequired?: boolean;
};

export const serviceLogin = (serviceLoginPayload: TServiceSigninBody): Promise<TServiceLoginResponse> => {
  return axios
    .post<TServiceSigninBody, AxiosResponse<TServiceLoginResponse>>(
      '/api/general/auth/business/signin',
      serviceLoginPayload
    )
    .then((response) => {
      return response.data;
    });
};
