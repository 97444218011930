import axios from '@src/services/axiosInstance';
import { AxiosResponse } from 'axios';

type TResetPasswordProps = {
  token: string;
  payload: {
    newPassword: string;
  };
};

type TResetPasswordResponse = {
  success: 0 | 1;
  data?: {
    msg: string;
  };
};

export const resetPassword = ({ token, payload }: TResetPasswordProps): Promise<TResetPasswordResponse> => {
  return axios
    .post<TResetPasswordProps['payload'], AxiosResponse<TResetPasswordResponse>>(
      '/api/general/auth/reset_pw',
      payload,
      { params: { token } }
    )
    .then(({ data }) => data);
};
