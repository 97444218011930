import React from 'react';
import { Redirect, Route, Switch, Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { Integrations } from '@sentry/tracing';
import './App.css';
import NotFoundPage from './404';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

const publicModules = import.meta.globEager('./publicPages/**/*');
const privateModules = import.meta.glob('./protectedPages/**/*');

const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;
const SENTRY_RELEASE = import.meta.env.VITE_SENTRY_RELEASE;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

if (SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    release: SENTRY_RELEASE,
    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
  });
}

const App: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <SentryRoute exact path="/">
          <Redirect to="/welcome" />
        </SentryRoute>
        {Object.keys(publicModules).map((path) => {
          if (path.match(/^.*\/(index.tsx|{.+}.tsx)$/)) {
            return (
              <PublicRoute
                path={path.replace(/{(.+)}.tsx$/, ':$1').replace(/.\/publicPages|\/index.tsx/gi, '')}
                component={publicModules[path].default}
                key={path}
                exact
              />
            );
          }
        })}
        {Object.keys(privateModules).map((path) => {
          if (path.match(/^.*\/(index.tsx|{.+}.tsx)$/)) {
            return (
              <PrivateRoute
                path={path.replace(/{(.+)}.tsx$/, ':$1').replace(/.\/protectedPages|\/index.tsx/gi, '')}
                component={privateModules[path]}
                key={path}
                exact
              />
            );
          }
        })}
        <SentryRoute component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default Sentry.withProfiler(App);
